import LoadingIndicator from 'components/onboarding/LoadingIndicator';
import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from './store';

const Routes = lazy(() => import('./routes'));

function App() {
    const {
        loading: { isLoading },
    } = useSelector((store: RootState) => store);
    return (
        <Suspense fallback={<LoadingIndicator />}>
            {isLoading && <LoadingIndicator />}
            <Routes />
        </Suspense>
    );
}

export default App;
