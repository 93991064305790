/* eslint-disable prefer-template */
/* eslint-disable no-bitwise */
import { toast } from 'react-toastify';
import jwt_decode from 'jwt-decode';
import CryptoJS from 'crypto-js';
// eslint-disable-next-line import/no-unresolved
import config from 'config/config';

export const handleErrorMessage = (description: string) =>
    description || 'Something went wrong, please try again later.';

// export default handleErrorMessage;

export const updateItemInArrayOfObjects = (
    array: any[],
    index: number,
    objectKey: string,
    value: any,
) => {
    const objectToUpdate = array[index];
    // eslint-disable-next-line no-param-reassign
    array[index] = { ...objectToUpdate, [objectKey]: value };
    return array;
};

export function formatArray(options: any[], value = '', label = '') {
    if (!options) return options;

    if (value && label)
        return options.map((opt) => ({
            label: opt[label],
            value: opt[value],
        }));

    return options.map((opt) => ({ label: opt?.label, value: opt.value }));
}

export const notifySuccess = (msg: string) => {
    toast.success(msg);
};

export const notifyError = (msg: string) => {
    toast.error(msg);
};

export const saveDataLocally = async (key: string, value: any) => {
    const data = localStorage.getItem(key);
    if (data) {
        const d = JSON.parse(data) as any;
        localStorage.setItem(key, JSON.stringify({ ...d, [key]: value }));
    } else {
        localStorage.setItem(key, JSON.stringify({ [key]: value }));
    }
};

export const getLocalData = (key: string) => {
    const data = localStorage.getItem(key) as any;
    if (!data) return;
    const d = JSON.parse(data);
    // eslint-disable-next-line consistent-return
    return d[key];
};

export const removeLocalData = (key: string) => localStorage.removeItem(key);

const storage = localStorage;

export const loadState = () => {
    try {
        const userState = storage.getItem('appState-fcmb-sme-admin');
        if (userState === null) {
            return {};
        }
        return JSON.parse(userState);
    } catch (error) {
        return {};
    }
};

// eslint-disable-next-line consistent-return
export const getAdminUser = (token: string) => {
    if (token) {
        const user = jwt_decode(token) as any;
        return user as any;
    }
};

export const setLocalState = (state: any) => {
    try {
        // userAuth,
        const {
            helper,
            loading,
            userAuth,
            assignedBusinessRequest,
            allBusinessRequest,
            consultantDetail,
            notification,
            ...restObj
        } = { ...state } as any;

        const serializedState = JSON.stringify(restObj);
        storage.setItem('appState-fcmb-sme-admin', serializedState);
    } catch (error) {
        console.log(error);
    }
};

// move to helpers when resolved
export const convertBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });

export function randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getTimeStamp = () => new Date().getTime();

export function generate14Num() {
    return Math.floor(Math.random() * 89999999999999 + 10000000000000).toString();
}

export const extractNotificationData = (notificationDatasList: any) =>
    notificationDatasList?.map(({ message, subject, id, createdAt, read }: ListProp) => ({
        message,
        subject,
        id,
        createdAt,
        read,
    }));

export const checkRoute = (pathname: string) => {
    if (pathname.includes('/vendor')) {
        return true;
    }
    return false;
};

export function createGuid() {
    function s4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (
        s4() +
        s4() +
        '-' +
        s4() +
        '-4' +
        s4().substr(0, 3) +
        '-' +
        s4() +
        '-' +
        s4() +
        s4() +
        s4()
    ).toLowerCase();
}
// generate header
export const generateHeader = () => {
    const dateToUse = new Date();
    const UTCTimestamps = dateToUse.toISOString().replace('Z', '');
    const dateInToken = UTCTimestamps.replace('T', '')
        .replace(':', '')
        .replace(':', '')
        .substring(0, UTCTimestamps.length - 7);
    const shaOneEncrypt = CryptoJS.SHA512(dateInToken + config.client_id + config.xtoken_password);
    const apiHeader = {
        'x-token': shaOneEncrypt.toString(CryptoJS.enc.Hex),
        Client_ID: config.client_id,
        'Ocp-Apim-Subscription-Key': config.subscription_key_value,
        UTCTimestamp: UTCTimestamps,
    };
    return apiHeader;
};
